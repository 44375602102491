import './App.css';
import AppUI from './AppUI';

function App() {
  return (
    <div className="App">
      <AppUI/>
    </div>
  );
}

export default App;
