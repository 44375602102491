import React from "react";
import "./InfoStyle.css";

function Info() {
  return (
    <div className="Container-X">
      <h1 className="title-I">
        Una nueva forma de trabajar, con Internet Dedicado
      </h1>
      <p className="Context-I">
        No queremos que tengas un Internet Dedicado solo por tenerlo, queremos
        que lo tengas para que tu forma de trabajar sea más eficiente y alcances
        todas las metas que tienes para tu negocio. Con un Internet
        verdaderamente dedicado y empresarial, puedes trabajar de forma óptima,
        eficiente, e incluso, más segura. Todo esto porque diseñamos un Internet
        para que puedas trabajar al máximo: un Internet puramente corporativo
        que no compite con usuarios domésticos, lo que te permite trabajar sin
        frustraciones a cualquier hora.
      </p>
    </div>
  );
}

export { Info };
