import React from "react";
import "./FootS.css";

function Footer() {
  return (
    <div className="Foot-S">
      <div className="Block-s">
        <div className="Section S-1">
          <h3 className="TitleS-S">Contáctanos</h3>
          <p>Teléfono: 0424 377 1789</p>
          <p>Teléfono: 0424 318 7828</p>
        </div>
        <div className="Section S-2">
          <h3 className="TitleS-S">Correo Electrónico</h3>
          <p>info@starconectisp.com</p>
        </div>
      </div>
      <div className="Block-2">
        <div className="Section S-3">
          <h3 className="TitleS-S">Chatea con nosotros</h3>
          <a href="https://wa.me/584123187828" className="Element-List">
            Whatsapp
          </a>
        </div>
        <div className="Section S-4">
          <h3 className="TitleS-S">Dirección</h3>
          <p>Centro Empresarial Europa</p>
          <p>Avenida Las Delicias, Maracay 2102, Aragua</p>
        </div>
      </div>
      <div className="Block-3">
        <h3 className="TitleS-S">Navegación</h3>
        <ol className="listNav">
          <li>
            <a href="#Inicio.Urlss" className="Element-List">
              Inicio
            </a>
          </li>
          <li>
            <a href="#Nosotros.urlss" className="Element-List">
              Nosotros
            </a>
          </li>
          <li>
            <a href="#Coberture.urlss" className="Element-List">
              Planes
            </a>
          </li>
          <li>
            <a href="#Plans.urlss" className="Element-List">
              Cobertura
            </a>
          </li>
          <li>
            <a href="#Conact.urlss" className="Element-List">
              Contacto
            </a>
          </li>
        </ol>
        <h3 className="TitleS-S">StarConect ISP</h3>
        <p>J-411353051</p>
        <p>Habilitación CONATEL nro: HGTS-00515</p>
      </div>
    </div>
  );
}

export { Footer };
