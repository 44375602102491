import React from "react";
import { Item } from "./Item";

const Benefits_A = [
  {
    profile: "1",
    title: "Velocidades Simétricas",
    description:
      "Nuestros servicios incluyen velocidades simétricas para cualquier tamaño de empresa.",
  },
  {
    profile: "2",
    title: "Rentable y Competitivo",
    description:
      "Reducir los costos,  mejorar la calidad a un precio adecuado y competitivo del mercado.",
  },
  {
    profile: "3",
    title: "Direcciones IP Estaticas",
    description:
      "Direcciones IP estáticas disponibles, administrado remotamente por nuestros expertos.",
  },
  {
    profile: "4",
    title: "Soporte Técnico 24/7",
    description:
      "Garantía de disponibilidad de servicio por un sólido soporte técnico disponible 24/7/365.",
  },
];

function Benefits() {
  return (
    <div className="Facts-plan" id="benefits.urlss">
      <h1 className="MasterTitle">Beneficios del Internet Dedicado</h1>
      <h2 className="SubTitle">La red mas avanzada de Venezuela</h2>
      <ol>
        {Benefits_A.map((object, index) => (
          <Item
            key={index}
            profile={object.profile}
            title={object.title}
            description={object.description}
          />
        ))}
      </ol>
    </div>
  );
}

export { Benefits };
