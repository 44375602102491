import React from "react";
import "./Hub.css";
import Fiber from "./Fiber.svg";
import Effect from "./Effect.svg";

function PrincipalView() {
  return (
    <div className="container" id="Inicio.Urlss">
      <div className="ImageContainer">
        <img src={Fiber} alt="Fiber" className="Image B" />
        <img src={Effect} alt="Effect" className="Effect Image A" />
      </div>
      <div className="ContainerText">
        <h1 className="StarText TitleStar">Star Conect ISP</h1>
        <h2 className="StarText Subtitle">Internet por radiofrecuencia que te ofrece velocidad y cobertura</h2>
        <p className="StarText Content">Servicio Exclusivo para Empresas, Negocios y Residencias.</p>
      </div>
    </div>
  );
}

export { PrincipalView };
