import React from "react";
import "./PlansS.css";
import { HiHome } from "react-icons/hi";
import { AiFillShop } from "react-icons/ai";
import { BsFillBuildingFill } from "react-icons/bs";

function Plans() {
  return (
    <div className="container-t3" id="Plans.urlss">
      <h1 className="titleCoberture-3">Planes de servicio disponible</h1>
      <p className="textInt-3">Planes de servicio de internet para el hogar</p>
      <div className="TheDivision"></div>
      <div className="Residential-Block">
        <div className="CardS Card-1">
          <HiHome className="iconS-M" />
          <h2 className="plan-name">BASIC</h2>
          <p className="plan-cost">$30.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN RESIDENCIAL</p>
            <p className="plan-Mbs"> 10MBPS</p>
          </div>
        </div>
        <div className="CardS Card-1">
          <HiHome className="iconS-M" />
          <h2 className="plan-name">HOME</h2>
          <p className="plan-cost">$45.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN RESIDENCIAL</p>
            <p className="plan-Mbs"> 20MBPS</p>
          </div>
        </div>
        <div className="CardS Card-1">
          <HiHome className="iconS-M" />
          <h2 className="plan-name">PRO</h2>
          <p className="plan-cost">$60.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN RESIDENCIAL</p>
            <p className="plan-Mbs"> 30MBPS</p>
          </div>
        </div>
      </div>
      <p className="textInt-3">Planes de servicio de internet para comercios</p>
      <div className="TheDivision"></div>
      <div className="Comercial-Block">
        <div className="CardS Card-2">
        <AiFillShop className="iconS-M"/>
          <h2 className="plan-name">BASIC</h2>
          <p className="plan-cost">$45.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN COMERCIAL</p>
            <p className="plan-Mbs"> 10MBPS</p>
          </div>
        </div>
        <div className="CardS Card-2">
        <AiFillShop className="iconS-M"/> 
          <h2 className="plan-name">BUSINESS</h2>
          <p className="plan-cost">$75.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN COMERCIAL</p>
            <p className="plan-Mbs"> 20MBPS</p>
          </div>
        </div>
      </div>
      <p className="textInt-3">Servicios de internet corporativo</p>
      <div className="TheDivision"></div>
      <div className="Corporative-Block">
        <div className="CardS Card-3">
          <BsFillBuildingFill className="iconS-M"/>
          <h2 className="plan-name">ESSENTIAL</h2>
          <p className="plan-cost">$100.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN CORPORATIVO</p>
            <p className="plan-Mbs">
              2MBPS
              <br />
              DEDICADO
            </p>
          </div>
        </div>
        <div className="CardS Card-3">
        <BsFillBuildingFill className="iconS-M"/>
          <h2 className="plan-name">STANDARD</h2>
          <p className="plan-cost">$200.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN CORPORATIVO</p>
            <p className="plan-Mbs">
              4MBPS
              <br />
              DEDICADO
            </p>
          </div>
        </div>
        <div className="CardS Card-3">
        <BsFillBuildingFill className="iconS-M"/>
          <h2 className="plan-name">MASTER</h2>
          <p className="plan-cost">$290.00</p>
          <div className="Foot-card">
            <p className="plan-type">PLAN CORPORATIVO</p>
            <p className="plan-Mbs">
              6MBPS
              <br />
              DEDICADO
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Plans };
