import React from "react";
import "./CobertureS.css";
import { App } from "./Slider";

function Coberture() {
  return (
    <div className="Content-T" id="Coberture.urlss">
      <div className="map-responsive-C2">
      <App className="slideS" />
      </div>
      <div className="information-block-C">
        <h1 className="titleCoberture-1">
          Mapa de cobertura de servicio de radio frecuencia
        </h1>
        <p className="textInt-1">
          Contamos con cobertura en las siguientes zonas de la ciudad de
          Maracay:
          <br/>
          <br/>
          Madre Maria de San Jose, Joaquin Crespo, Los Tacariguas, Francisco
          Linares Alcantara, Caña de Azúcar, Andres Eloy Blanco, Jose Casanova
          Godoy.
        </p>
      </div>
    </div>
  );
}

function Contact() {
  return (
    <div className="Content-T2" id="Conact.urlss">
      <div className="map-responsive">
      <iframe
        title="Ubication"
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3925.8948504176374!2d-67.58836978520357!3d10.270055592666337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDE2JzEyLjIiTiA2N8KwMzUnMTAuMyJX!5e0!3m2!1ses!2sve!4v1687870223469!5m2!1ses!2sve"
        width="600"
        height="400"
        style={{borderRadius: 10, border: 0}}
        loading="lazy"
      ></iframe>
      </div>
      <div className="information-block">
        <h1 className="titleCoberture-2">
          Contactenos
        </h1>
        <p className="textInt-2">
          Av. Las delicias Centro Empresarial Europa piso 3 ofic. 3-11, 
          Urb. La Floresta, Maracay Edo. Aragua.
          <br/>
          Teléfono: 0243-2428970
          <br/>
          Horario:
          <br/>
          Lunes a Viernes 
          <br/>
          De 8:00 A.M. - 4:00 P.M.
        </p>
      </div>
    </div>
  );
}

export { Coberture, Contact };
