import React from "react";
import { NavBar } from "../Components/NavBar";
import { PrincipalView } from "../Components/PrincipalView";
import { Block } from '../Components/Blocks';
import { BlockP2 } from '../Components/BlocksP2';
import { Benefits } from "../Components/Benefits";
import { Info } from "../Components/Info"; 
import { Coberture, Contact } from "../Components/Coberture";
import { Plans } from "../Components/Planes";
import { Footer } from "../Components/Foot";

export default function AppUI() {
  return (
    <div>
      <NavBar/>
      <PrincipalView/>
      <Block/>
      <Benefits/>
      <BlockP2/>
      <Info/>
      <Coberture/>
      <Plans/>
      <Contact/>
      <Footer/>
    </div>
  );
}
export { AppUI };
