import React from "react";
import "./BlocksS2.css";
import Asset_C from "./Asset_C.svg";

function BlockP2() {
  return (
    <div className="Content-Block-t2" id="Nosotros.urlss">
      <img src={Asset_C} alt="Assets" className="asset-A-t2" />
      <div className="information-block-t2">
        <h1 className="titleH2-t2">Nuestra Visión</h1>
        <p className="textInt-t2">
          Ser la empresa de servicio de Internet dedicado lider en Venezuela. Con la <b>Red Más Poderosa</b> y <b>El mejor equipo humano</b>, soporte técnico real, garantizando alta calidad, disponibilidad, rapidez, honestidad, confiabilidad y Alta excelencia.
        </p>
      </div>
    </div>
  );
}

export { BlockP2 };
