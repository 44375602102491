import React from "react";
import "./BenefitsS.css";

function Item(props) {
  return (
    <div className="ItemS">
      <div className="Trusten">
        <img
          src={require(`./Images/IconS_(${props.profile}).svg`)}
          alt="Images"
          className="imageIcon"
        />
        <h2 className="NameItem">{props.title}</h2>
        <p className="desS">{props.description}</p>
      </div>
    </div>
  );
}

export { Item };