import "./NavbarS.css";
import { Component } from "react";
import  IconStar  from "./IconStar.png";
import { SiInstagram } from "react-icons/si";
import { SiWhatsapp } from "react-icons/si";

class NavBar extends Component {
  state={clicked: false};
  handleClick = () => {
    this.setState({clicked: !this.state.clicked})
  }
  render() {
  return (
    <div className="NavigationBar-Container">
      <nav className="NavBar-Comp">
      <img className="iconStar" src={IconStar} alt="favicon" />
        <div className="Nav-Left" id={this.state.clicked ? "Nav-Left-active" : "Nav-Left"}>
            <img className="iconStar2" src={IconStar} alt="favicon" />
          <a href="#benefits.urlss" className="StarLetters">
            Beneficios
          </a>
          <a href="#Nosotros.urlss" className="StarLetters">
            Nosotros
          </a>
          <a href="#Coberture.urlss" className="StarLetters">
            Cobertura
          </a>
          <a href="#Plans.urlss" className="StarLetters">
            Planes
          </a>
          <a href="#Conact.urlss" className="StarLetters">
            Contacto
          </a>
        </div>
        <div className="Nav-rigth">
          <a href="https://www.instagram.com/starconectisp/">
            <SiInstagram className="SiIcon" />
          </a>
          <a href="https://wa.me/584123187828">
            <SiWhatsapp className="SiIcon" />
          </a>
          <div className="Extend-Menu" onClick={this.handleClick}>
          <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}>
          </i>
        </div>
        </div>
      </nav>
    </div>
  );
}
}
export { NavBar };
