import React from "react";
import "./BlocksS.css";
import Asset_B from "./Asset_B.svg";

function Block() {
  return (
    <div className="Content-Block">
      <div className="information-block">
        <h1 className="titleH2">Internet Dedicado</h1>
        <p className="textInt">
          Tu Internet es mucho más que solo tu ancho de banda, también debe
          estar preparado para los desafíos diarios a los que se enfrenta tu
          empresa. Nosotros lo sabemos y por eso, te damos un Internet Dedicado.
          Pero ¿Qué significa eso? Un enlace dedicado a Internet te da una
          calidad de navegación más alta, además, garantiza que su uso sea más
          seguro, estable, siempre constante y escalable según lo necesites. Se
          trata de un Internet verdaderamente corporativo que llevará tu empresa
          al siguiente nivel.
        </p>
      </div>
      <img src={Asset_B} alt="Assets" className="asset-A" />
    </div>
  );
}

export { Block };
