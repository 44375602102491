import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import "./CobertureS.css";

class App extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 200,
      slidesToShow: 1,
      slidesToScroll: 1,
      appendDots: (dots) => {
        return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
      },
    };

    return (
      <Slider {...settings} style={{ width: 580, height: 400, minWidth: 380,}} >
        <div className="map-responsive-C">
          <iframe
            title="map-1"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31408.225117949733!2d-67.59917989895011!3d10.259314027956346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803b511588a7b7%3A0x25cc236b53f08eba!2sParroquia%20Madre%20Mar%C3%ADa%20de%20San%20Jos%C3%A9%2C%202101%2C%20Aragua!5e0!3m2!1ses!2sve!4v1687353847949!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0,}}
            loading="lazy"
          ></iframe>
        </div>
        <div className="map-responsive-C">
          <iframe
            title="map-2"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15705.466206862997!2d-67.5816641948339!3d10.231991854026612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803cb54ef69f8f%3A0x39085a14827d5f3c!2sParroquia%20Joaqu%C3%ADn%20Crespo%2C%202103%2C%20Aragua!5e0!3m2!1ses!2sve!4v1687353278006!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
        <div className="map-responsive-C">
          <iframe
            title="map-3"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62812.4221421157!2d-67.67659343624315!3d10.279593293027165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803bbdc41d3f47%3A0xf5a367c73214d27c!2sUrbana%20Los%20Tacariguas%2C%202103%2C%20Aragua!5e0!3m2!1ses!2sve!4v1687353545073!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
        <div className="map-responsive-C">
          <iframe
            title="map-4"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62827.899608001266!2d-67.58577193637737!3d10.201454882840837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8023a7e504cf85%3A0xfe4c3ba07cffb5e!2sFrancisco%20Linares%20Alc%C3%A1ntara%2C%20Aragua!5e0!3m2!1ses!2sve!4v1687353606214!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
        <div className="map-responsive-C">
          <iframe
            title="map-5"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31406.47194573981!2d-67.6422367!3d10.2769688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803b97cdb2c051%3A0x95c48b9a53e137a5!2zQ2HDsWEgRGUgQXrDumNhciwgMjEwNSwgQXJhZ3Vh!5e0!3m2!1ses!2sve!4v1687353747742!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
        <div className="map-responsive-C">
          <iframe
            title="map-6"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62812.799964655744!2d-67.64649098624635!3d10.277692843997137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803b1292a0fa8d%3A0xe403de6c32ca93bb!2sParroquia%20Andr%C3%A9s%20Eloy%20Blanco%2C%202103%2C%20Aragua!5e0!3m2!1ses!2sve!4v1687354036725!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
        <div className="map-responsive-C">
          <iframe
            title="map-7"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37355.38426458926!2d-67.61891969644886!3d10.221109655665428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803ce90428ba59%3A0x87bbe8a2dffcd220!2sParroquia%20Jos%C3%A9%20Casanova%20Godoy%2C%202103%2C%20Aragua!5e0!3m2!1ses!2sve!4v1687354096557!5m2!1ses!2sve"
            width="580"
            height="400"
            style={{ borderRadius: 10, border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
      </Slider>
    );
  }
}
export { App };
